<template lang="pug">
#ready-to-start.interrupt-step-component
  icon.rts-icon(data="@icon/person-waving.svg")

  h1 Welcome back!

  p Let's continue your exam from where you left off.

  button.button(@click="gotoNext()") Continue exam
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('startVideo')
}
</script>
